import React, { useState } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import Main from './Main'
import Details from './Details'
import './css/App.css'

function App () {
  const [showDetails, setShowDetails] = useState(false)
  const [content, setContent] = useState('')

  const handleTitleClick = (title) => {
    if (title === 'GitHub') {
      window.open('https://github.com/yingchenxing')
      return
    }
    if (title === 'Contact') {
      window.open('mailto:xander11chan@gmail.com')
      return
    }
    if (title === content) {
      setShowDetails(false)
      setContent('')
      return
    }
    setContent(title)
    setShowDetails(true)
  }

  return (
    <div className="App">
      <motion.div
        className="main-wrapper"
        initial={{ width: '100%' }}
        animate={{ width: showDetails ? '50%' : '100%' }}
        transition={{ duration: 0.5, ease: 'easeInOut' }}
      >
        <Main onTitleClick={handleTitleClick} />
      </motion.div>

      <motion.div
        className="details-wrapper"
        initial={{ width: '0%', maxHeight: '0px', overflow: 'hidden' }}
        animate={{ width: showDetails ? '50%' : '0%', maxHeight: showDetails ? '850px' : '0px', overflow: 'hidden' }}
        transition={{ duration: 0.5, ease: 'easeInOut' }}
      >
        {showDetails && (
          <Details content={content} />
        )}
      </motion.div>

    </div>
  )
}

export default App
